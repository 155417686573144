import * as Turbo from "@hotwired/turbo";
// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
//    OR
//    <%= vite_javascript_tag "application", "data-turbo-track": "reload", defer: true %>
console.log("Vite ⚡️ Rails");
// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails"
);

// Example: Load Rails libraries in Vite.
//
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// **IMPORTANT** Turbo.session.drive = false prevents js from not being reloaded on new page load
Turbo.session.drive = false;
Turbo.start();

// IMPORTANT run bin/vite build --clear --mode=test if assets are failing in TEST
// IMPORTANT https://vite-ruby.netlify.app/guide/deployment.html#using-heroku
//import "@hotwired/turbo-rails";
//import "../controllers";
//import "../custom/static_pages_hamburger_menu";
//import "../custom/pricing_toggler";
//import "../custom/dark_mode_toggler";
//import "../custom/dashboards/hamburger_menu";
